<template>
  <div class="card-box">

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="随访" name="0">
        <div slot="label" class="tabs_label" >
          随访
          <el-badge :hidden="follow_num == 0" :value="follow_num" size="mini" class="item"></el-badge>
        </div>
      </el-tab-pane>
      <el-tab-pane label="随访服务记录" name="1">

      </el-tab-pane>
    </el-tabs>

    <div class="card-content">

      <template v-if="list.length != 0">
        <el-row :gutter="100">
          <el-col :span="6" v-for="item in list" :key="item.orderNo" >
            <div class="card"
                 @click="linkDeta(item)"
                 style="cursor: pointer"
                 :class="[
                     ( item.reportType == 0 ?
                  'DarkBlue' : ( item.reportType == 1 ?
                   'LightBlue' : ( item.reportType == 2 ?
                    'LightGreen' : 'Violet' ) )),
                    ( item.isRead ? 'red' : '' )
                 ]">

              <div class="card-header">
                <div class="card-header-left">
                  <p>{{ $store.state.reportType.filter((items) => { return items.dictKey == item.reportType })[0].dictValue }}</p>
                </div>
                <div class="card-header-right">
                  <p>{{ item.callCount == 0 ? "未呼叫" : "呼叫" + item.callCount + "次" }}</p>
                </div>
              </div>

              <div class="card-item">
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <p>{{ item.name }}</p>
                </el-tooltip>
                <p>{{ item.gender == 0 ? "女" : "男" }}</p>
                <p>{{ item.phoneNo }}</p>
              </div>

              <div class="card-footer">

                <div class="card-footer-left">
                  <p class="card-footer-left-time" v-if="item.appointDate" >{{ item.appointDate }}</p>
                </div>

                <!--                <div class="card-footer-right">-->
                <!--                   <el-button round size="small">认领</el-button>-->
                <!--                </div>-->

              </div>

            </div>
          </el-col>
        </el-row>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageNum"
            :page-sizes="[12, 24, 48, 96]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </template>

      <template v-else>
        <NoList>
          <template #text>
            <p>暂无数据</p>
          </template>
        </NoList>
      </template>

    </div>

  </div>
</template>

<script>
export default {
  name: "FollowService",
  data() {
    return {
      activeName: 0,
      follow_num: 0,
      page: {
        pageNum: 1,
        pageSize: 12
      },
      total: 0,
      list: []
    }
  },
  created() {
    if(this.$route.query.activeName != null) {
      this.activeName= this.$route.query.activeName;
    }

    // 未读消息
    this.get_unread_message();
    // 获取列表
    this.get_list(this.page);
  },
  methods: {
    linkDeta(e) {
      this.$router.push({
        path: "/CellDeta",
        query: {
          id: e.serviceId,
          state: this.activeName
        }
      })
    },
    get_list(y) {
      if(this.activeName == 0) {
        y["status"]= "0";
        this.$get("cs/follow",y)
            .then(res => {
              if(res) {
                console.log(res.data.list);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }else {
        y["status"]= "1";
        this.$get("cs/follow",y)
            .then(res => {
              if(res) {
                console.log(res.data.list);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }
    },
    // 未读消息
    get_unread_message() {
      this.$get("cs/message")
          .then(res => {
            if(res) {
              res.data.forEach(item => {
                if(item.type != '0') {
                  this.follow_num = item.unreadCount;
                }
              })
            }
          })
    },
    handleCurrentChange(e) {
      this.page.pageNum= e;
      this.get_list(this.page);
    },
    handleSizeChange(e) {
      this.page.pageNum= 1;
      this.page.pageSize= e;
      this.get_list(this.page);
    },
    handleClick() {
      this.page.pageNum = 1;
      this.page.pageSize = 8;
      this.list= [];

      this.get_list(this.page);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/Service/card";
</style>
